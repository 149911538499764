/* global ajax_object */
// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; import './autoload/lazyload.js'; // eslint-disable-line
// Import local dependencies
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';
import Masonry from 'masonry-layout';
import Foundation from './autoload/foundation';
import Cookies from 'js-cookie';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });
  jarallax(document.querySelectorAll('.decorative'), {
    speed: 0.5,
  });

  $(window).on('load changed.zf.mediaquery', function () {
    if (Foundation.MediaQuery.is('medium down')) {
      jarallax(document.querySelectorAll('.jarallax'), 'destroy');
    } else {
      jarallax(document.querySelectorAll('.jarallax'), {
        speed: 0.5,
      });
    }
  });

  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    threshold: 0.5,
    once: true,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */

let popupWasShown = Cookies.get('popup_was_shown') === 'true' ? true : false;

$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  if ($('#subscribe-popup').length && !popupWasShown) {
    setTimeout(function () {
      $('#subscribe-popup').foundation('open');
      Cookies.set('popup_was_shown', true);
    }, 2000);
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});

$('.menu-icon').on('click', () => {
  $('.menu-icon').toggleClass('is-active');
  $('#main-menu').toggleClass('is-active');
  $('.mob-menu-overlay').toggleClass('is-active');
  $('html').toggleClass('no-scroll');
});

$('body').on('click', (e) => {
  if ($(e.target).hasClass('mob-menu-overlay')) {
    $('.menu-icon').removeClass('is-active');
    $('#main-menu').removeClass('is-active');
    $('.mob-menu-overlay').removeClass('is-active');
    $('html').removeClass('no-scroll');
  }
});

$('.renderings-slider').slick({
  slidesToShow: 1,
  rows: 0,
  arrows: true,
  dots: false,
  adaptiveHeight: true,
  fade: true,
  // responsive: [
  //   {
  //     breakpoint: 500,
  //     settings: {
  //       arrows: false,
  //     },
  //   },
  // ],
});

$('.map-button:not(.map-button--no-click-event)').on('click', function () {
  let tabIndex = $(this).data('tab');
  let idSlug = $(this).data('slug');
  let tabId = '#' + idSlug + 'panel' + tabIndex;
  if (idSlug) {
    $('#' + idSlug + 'map-tabs').foundation('selectTab', tabId);
  }
});

$('.site-plan__tabs').on('change.zf.tabs', function (e, current) {
  let idSlug = $(current).data('slug');
  let selector =
    '.' + idSlug + 'map-button[data-tab="' + $(current).data('tab') + '"]';
  $('.' + idSlug + 'map-button.is-active').removeClass('is-active');
  $(selector).addClass('is-active');
});

function setTabsColor() {
  let primaryColor = $(
    '.site-plan__main-tabs-content > .tabs-panel.is-active'
  ).data('primary-color');
  let secondaryColor = $(
    '.site-plan__main-tabs-content > .tabs-panel.is-active'
  ).data('secondary-color');
  let outlineColor = $(
    '.site-plan__main-tabs-content > .tabs-panel.is-active'
  ).data('outline-color');
  $('.site-plan__main-tabs-content').css('--primary-color', primaryColor);
  $('.site-plan__main-tabs-content').css('--secondary-color', secondaryColor);
  $('.site-plan__main-tabs-content').css('--outline-color', outlineColor);
}

if ($('#siteplan-tabs').length) {
  setTabsColor();
}

$('#siteplan-tabs').on('change.zf.tabs', setTabsColor);

$('.js-landing-accordion').on('down.zf.accordion', function (e, current) {
  $(current)
    .find('.js-gallery-preview')
    .slick({
      slidesToShow: 1,
      rows: 0,
      fade: true,
      dots: false,
      arrows: false,
      asNavFor: '.js-gallery-nav.slick-initialized',
      responsive: [
        {
          breakpoint: 1016,
          settings: {
            arrows: true,
            adaptiveHeight: true,
          },
        },
      ],
    });
  $(current).find('.js-gallery-nav').slick({
    slidesToShow: 3,
    rows: 0,
    dots: false,
    arrows: true,
    asNavFor: '.js-gallery-preview.slick-initialized',
    focusOnSelect: true,
  });
});

$('.js-landing-accordion').on('up.zf.accordion', function (e, current) {
  $(current).find('.slick-initialized').slick('unslick');
});

$('select.gfield_select').prepend(
  $('<option selected hidden>').text('Choose one...')
);

$('.js-goto-form').on('click', function (e) {
  e.preventDefault();
  let offsetTop = $('.form-wrapper').offset().top - 200;
  $('html').animate({ scrollTop: offsetTop - 50 }, 500, function () {
    $('.form-wrapper').addClass('bounce');
  });
});

$('.form-wrapper').on('animationend', function () {
  $(this).removeClass('bounce');
});

// Select all links with hashes
$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .not('[role="tab"]')
  .click(function (event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') ==
        this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate(
          {
            scrollTop: target.offset().top,
          },
          1000,
          function () {
            // Callback after animation
            // Must change focus!
            var $target = $(target);
            $target.focus();
            if ($target.is(':focus')) {
              // Checking if the target was focused
              return false;
            } else {
              $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
              $target.focus(); // Set focus again
            }
          }
        );
      }
    }
  });

$('.js-brands .brands__item').on('click', function () {
  let id = $(this).data('id');
  if ($(this).hasClass('is-active')) {
    $(this)
      .removeClass('is-active')
      .closest('.brands__cards')
      .removeClass('is-active');
    hideTabs();
  } else {
    $(this)
      .addClass('is-active')
      .siblings('.brands__item')
      .removeClass('is-active');
    $(this).closest('.brands__cards').addClass('is-active');
    showTab(id);
  }
});

function showTab(id) {
  $(`.brands__content`).addClass('hide');
  $(`.brands__content[data-id="${id}"]`).removeClass('hide');
  $(`.brands__logo`).addClass('hide');
  $(`.brands__logo[data-id="${id}"]`).removeClass('hide');
}
function hideTabs() {
  $('.brands__content').addClass('hide');
  $(`.brands__logo`).addClass('hide');
}

if ($('.gallery-grid').length) {
  let masonryOptions = {
    itemSelector: '.gallery-grid__item',
    columnWidth: '.gallery-grid__sizer',
    percentPosition: true,
    gutter: 16,
    transitionDuration: 0,
    initLayout: false,
  };
  let galleryGrid = new Masonry('.gallery-grid', masonryOptions);
  $(window).on('load changed.zf.mediaquery', function () {
    let isMobile = Foundation.MediaQuery.is('small down');
    if (isMobile) {
      galleryGrid.destroy();
      $('.gallery-grid').slick({
        dots: false,
        arrows: true,
        slidesToShow: 1,
        rows: 0,
        infinite: true,
        appendArrows: '.gallery-grid__arrows',
      });
    } else {
      if ($('.gallery-grid').hasClass('slick-initialized')) {
        $('.gallery-grid').slick('unslick');
      }
      galleryGrid.layout(masonryOptions);
    }
  });
}

$('.js-cat-link').on('click', function () {
  if ($('body').hasClass('loading')) return;
  let $this = $(this);
  let category = $(this).data('category');
  let data = {
    action: 'load_posts',
    category,
  };
  $.ajax({
    url: ajax_object.ajax_url,
    type: 'GET',
    data,
    beforeSend() {
      $('body').addClass('loading');
    },
    success: function (resp) {
      $this
        .closest('.categories')
        .find('.js-cat-link')
        .removeClass('is-active');
      $this.addClass('is-active');
      $('.posts-list').html(resp.html);
      $('body').removeClass('loading');
    },
  });
});
